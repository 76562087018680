@import url('https://fonts.googleapis.com/css2?family=Goudy+Old+Style&family=Open+Sans:wght@300;400;600;700&display=swap');

/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #000000;
  background-color: #FFFFFF;
}

h1 {
  font-family: 'Goudy Old Style', serif;
  font-size: 3rem;
  font-weight: 700;
  color: #a46e08;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

/* Header styles */
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d7cfc5;
  padding: 1rem;
  border-radius: 5px;
}

.search {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="text"] {
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  margin-right: 1rem;
  flex-grow: 1;
}

select {
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
}

/* Video list styles */
.video-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.video {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.video:hover {
  transform: translateY(-5px);
}

.video-thumbnail {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.video-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-info {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #afa081;
}

.video-title {
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
  color: #000000;
}

.video-description {
  margin: 0;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #394b85;
  flex-grow: 1;
}

/* Selected video styles */
.selected-video {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
}

.embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
  }

  .search {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search input[type="text"] {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
  }

  .search select {
    width: 100%;
  }

  .video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .video {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 2rem;
  }

  .video-list {
    margin: 0;
    padding: 0;
  }

  .video {
    margin-bottom: 1rem;
  }
}
